import React from "react";
import { Edit, SimpleForm, TextInput, DateInput } from "react-admin";
import { RichTextInput } from "ra-input-rich-text";

const BlogEdit = () => {
  return (
    <Edit title="Edit a Blog">
      <SimpleForm>
        <TextInput className="w-full" source="link" />
        <TextInput className="w-full" source="image" />
        <TextInput className="w-full" source="category" />
        <TextInput className="w-full" source="title" />
        <TextInput className="w-full" source="desc" />
        <TextInput className="w-full" source="firstBanner" />
        {/* <TextInput className="w-full" source="secondBanner" />
        <TextInput className="w-full" multiline={true} source="firstHeading" /> */}
        {/* <TextInput
          className="w-full"
          multiline={true}
          source="firstParagraph"
        /> */}
        {/* <RichTextInput
          className="w-full"
          multiline={true}
          source="firstParagraph"
        /> */}
        {/* <TextInput className="w-full" multiline={true} source="secondHeading" />
        <TextInput
        className="w-full"
        multiline={true}
        source="secondParagraph"
        />
        <TextInput className="w-full" multiline={true} source="thirdHeading" />
        <TextInput
        className="w-full"
        multiline={true}
        source="thirdParagraph"
      /> */}
        <RichTextInput source="body" className="w-full" />
        <DateInput label="Published" source="date" />
      </SimpleForm>
    </Edit>
  );
};

export default BlogEdit;
