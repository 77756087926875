import { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from "react-admin";

const BlogList = (props) => {
  useEffect(() => {
    document.title = `Elfayomy | Admin`;
  }, []);
  return (
    <List {...props}>
      <Datagrid>
        {/* <TextField source="id" /> */}
        <TextField source="title" />
        <TextField source="category" />
        <TextField source="image" />
        <TextField source="video" />
        <TextField source="behance" />
        {/* <TextField source="dribbble" /> */}
        <EditButton basepath="/enblogs" />
        <DeleteButton basepath="/enblogs" />
      </Datagrid>
    </List>
  );
};

export default BlogList;
