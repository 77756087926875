import { RichTextInput } from "ra-input-rich-text";
import React from "react";
import { Create, SimpleForm, TextInput, DateInput } from "react-admin";

const BlogCreate = (props) => {
  return (
    <Create title="Create a Blog" {...props}>
      <SimpleForm>
        <TextInput className="w-full" source="link" />
        <TextInput className="w-full" source="image" />
        <TextInput className="w-full" source="category" />
        <TextInput className="w-full" source="desc" />
        <TextInput className="w-full" source="firstBanner" />
        <TextInput className="w-full" source="title" />
        {/* <TextInput className="w-full" source="secondBanner" /> */}
        {/* <TextInput multiline={true} className="w-full" source="firstHeading" />
        <TextInput
          multiline={true}
          className="w-full"
          source="firstParagraph"
        />
        <TextInput multiline={true} className="w-full" source="secondHeading" />
        <TextInput
          multiline={true}
          className="w-full"
          source="secondParagraph"
        />
        <TextInput multiline={true} className="w-full" source="thirdHeading" />
        <TextInput
          multiline={true}
          className="w-full"
          source="thirdParagraph"
        /> */}
        <RichTextInput source="body" className="w-full" />
        <DateInput label="Published" source="date" />
      </SimpleForm>
    </Create>
  );
};

export default BlogCreate;
