import { useEffect } from "react";
import {
  List,
  Datagrid,
  SimpleForm,
  TextField,
  TextInput,
  Edit,
  EditButton,
  DeleteButton,
} from "react-admin";

export const CVList = (props) => {
  useEffect(() => {
    document.title = `Elfayomy | Admin`;
  }, []);
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="link" label="CV Link" />
        <EditButton basepath="/cv" label="Edit" />
        <DeleteButton basepath="/cv" label="Delete" />
      </Datagrid>
    </List>
  );
};

export const CVEdit = () => {
  return (
    <Edit title="Edit CV">
      <SimpleForm>
        <TextInput className="w-full" disabled source="id" label="CV id" />
        <TextInput className="w-full" source="link" label="CV Link" />
      </SimpleForm>
    </Edit>
  );
};
