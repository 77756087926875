import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";

const ProjectCreate = () => {
  return (
    <Create title="Create a Project">
      <SimpleForm>
        {/* <TextInput className="w-full" source="id" /> */}
        <TextInput className="w-full" source="title" />
        <TextInput className="w-full" source="category" />
        <TextInput className="w-full" source="image" />
        <TextInput className="w-full" source="video" />
        <TextInput className="w-full" source="behance" />
        <TextInput className="w-full" source="dribbble" />
      </SimpleForm>
    </Create>
  );
};

export default ProjectCreate;
