import { useEffect } from "react";
import {
  List,
  Datagrid,
  SimpleForm,
  TextField,
  TextInput,
  Create,
  Edit,
  EditButton,
  DeleteButton,
} from "react-admin";
export const CertificatesList = (props) => {
  useEffect(() => {
    document.title = `Elfayomy | Admin`;
  }, []);
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="link" label="Certificate Link" />
        <EditButton basepath="/certificates" label="Edit" />
        <DeleteButton basepath="/certificates" label="Delete" />
      </Datagrid>
    </List>
  );
};

export const CertificatesCreate = (props) => {
  return (
    <Create title="Edit Certificate" {...props}>
      <SimpleForm>
        {/* <TextInput className="w-full" source="id" label="Certificate Id" /> */}
        <TextInput className="w-full" source="link" label="Certificate Link" />
      </SimpleForm>
    </Create>
  );
};
export const CertificatesEdit = (props) => {
  return (
    <Edit title="Edit Certificate" {...props}>
      <SimpleForm>
        <TextInput
          className="w-full"
          source="id"
          disabled
          label="Certificate Id"
        />
        <TextInput className="w-full" source="link" label="Certificate Link" />
      </SimpleForm>
    </Edit>
  );
};
