import React, { useEffect } from "react";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
} from "react-admin";

const BlogList = (props) => {
  // Change the site name to admin when this component mounts
  useEffect(() => {
    document.title = `Elfayomy | Admin`;
  }, []);
  return (
    <List {...props} className="overflow">
      <Datagrid>
        {/* Showing the blog data to edit and create new blogs*/}
        <TextField source="link" label="Link" />
        <TextField source="category" label="Category" />
        <TextField source="image" label="Image" />
        <TextField source="firstBanner" label="First Banner" />
        {/* <TextField source="secondBanner" label="Second Banner" /> */}
        {/* <TextField multiline={true} source="body" /> */}
        <DateField source="date" label="Date" />
        <EditButton basepath="/blogs" label="Edit" />
        <DeleteButton basepath="/blogs" label="Delete" />
      </Datagrid>
    </List>
  );
};

export default BlogList;
