import CryptoJS from "crypto-js";

const hashPassword = (password) => {
  return CryptoJS.SHA256(password).toString(CryptoJS.enc.Base64);
};

const comparePasswords = (password, hashedPassword) => {
  const hashedProvidedPassword = hashPassword(password);
  return hashedProvidedPassword === hashedPassword;
};

const authProvider = {
  users: [
    {
      username: "elfayomy",
      passwordHash: "g79BQvufYwQF9+GeyllyIfkwjH+kLVekzW6MDNUeEYM=",
    },
  ],
  login: ({ username, password }) => {
    const user = authProvider.users.find((u) => u.username === username);
    if (!user || !comparePasswords(password, user.passwordHash)) {
      return Promise.reject(new Error("Invalid username or password"));
    }
    localStorage.setItem("username", username);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("username");
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("username") ? Promise.resolve() : Promise.reject(),
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("username");
      return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () =>
    Promise.resolve({
      id: "user",
      fullName: "Ahmed Elfayomy",
      avatar: "/icon.png",
    }),
  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
